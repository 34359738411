import React, { useState, useEffect } from "react";
import "./App.css";
import Routes from "./Pages/Desktop/Layout/Routes/Routes";
import MobileRoutes from "./Pages/Mobile/Layout/Routes/Routes";
import Header from "./Pages/Desktop/Layout/Header/Header";
import MobileHeader from "./Pages/Mobile/Layout/Header/Header";
import Footer from "./Pages/Desktop/Layout/Footer/Footer";
import MobileFooter from "./Pages/Mobile/Layout/Footer/Footer";
import { IsMobileBrowser } from "./Services/common";
import ContactPopup from "./Components/ContactPopup/ContactPopup";
import AgeCheck from "./Components/AgeCheck/AgeCheck";
import ConfirmCookies from "./Components/ConfirmCookies/ConfirmCookies";
import { GetKeyWords, GetPartners } from "./Services/service";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router";
import GameDemo from "./Components/GameDemo/GameDemo";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ScrollToTop from './Components/ScrollToTop/scrollToTop'

export const App = () => {
  const [keywords, setKeyWords] = useState(null);
  useEffect(() => {
    GetKeyWords()
      .then((response) => {
        setKeyWords(response.data);
      })
      .catch((err) => {});
  }, []);
  const [showAgeCheck, setShowAgeCheck] = useState(true);
  const [showConfirmCookies, setShowConfirmCookies] = useState(true);

  function showAgeCheckPopup() {
    return localStorage.getItem("confirmAge");
  }

  function handleCloseAgeCheck() {
    localStorage.setItem("confirmAge", "true");
    setShowAgeCheck(false);
  }

  function confirmCookiesShow() {
    return localStorage.getItem("confirmCookies");
  }

  function handleCloseConfirmCookies() {
    localStorage.setItem("confirmCookies", "true");
    setShowConfirmCookies(false);
  }

  return (
    <>
      <ScrollToTop/>
      <>
        <Helmet>
          {keywords && (
            <meta
              name="keywords"
              content={keywords.map((item, index) => item.keyItem)}
            />
          )}
        </Helmet>
        <div className="main-container">
          {IsMobileBrowser() ? <MobileHeader /> : <Header />}
          <div className={"mainContainer"}>
            {showAgeCheck && !showAgeCheckPopup() && (
              <AgeCheck onClose={() => handleCloseAgeCheck()} />
            )}
            {IsMobileBrowser() ? <MobileRoutes /> : <Routes />}
            {showConfirmCookies && !confirmCookiesShow() && (
              <ConfirmCookies onClose={() => handleCloseConfirmCookies()} />
            )}
          </div>
          {IsMobileBrowser() ? <MobileFooter /> : <Footer />}
        </div>
      </>
    </> 
  );
};
