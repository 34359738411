import React, { Component, useEffect, useState } from "react";
import CustomSlider from "../../../Components/Slider/Slider";
import MobileSlider from "../../../Components/Mobile/MobileSlider/MobileSlider";
import { GetBanners, GetCategories } from "../../../Services/service";
import styles from "../HomePage/HomePage.module.css";
import {
    GetGameRedirectUrl,
    GetStaticContentBaseUrl,
} from "../../../Services/common";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import PartnersSlider from "../../../Components/PartnersSlider/PartnersSlider";

const HomePage = () => {
    const [data, setData] = useState(null);
    const [banner, setBanner] = useState(null);
    useEffect(() => {
        
    }, []);
    useEffect(() => {
        GetCategories()
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });
            GetBanners("Main", "Mobile")
            .then((response) => {
                setBanner(response.data);
            })
            .catch((err) => {});

    }, [])
    return (
        banner && (
            <>
                {
                    <a
                        href={banner[0].redirectUrl}
                        target={
                            banner[0].isGameRedirect || banner[0].isExternalRedirect
                                ? "_blank"
                                : "_self"
                        }
                    >
                        <img
                            className={styles.mainBanner}
                            src={GetStaticContentBaseUrl() + banner[0].filePath}
                            alt={banner[0].imageAltAttribute}
                        />
                    </a>
                }

                <div className={styles.gamesContainer}>
                    <div className={styles.gamesWrapper}>
                        {Array.isArray(data) &&  data.length > 0 && data.map((item, index) => (
                                <NavLink key={index} to={"" + item.redirectUrl} className={ styles.gamesItem + " " + styles[item.categoryName]}>
                                    <img
                                        className={styles.categoryImg}
                                        src={"/assets/images/HomePageMobile/" + item.categoryName + ".png" }></img>
                                    <div className={ styles.categoryTitle + " " + styles[item.categoryName]}>
                                        {item.categoryName}
                                    </div>
                                </NavLink>
                            ))}
                    </div>
                </div>

                {/* <div className={styles.gamesContainer}>
                <div className={styles.gamesWrapper}>
                    <a className={styles.gameItem + ' ' + styles.bigGameItem} href={GetGameRedirectUrl('fruit5')} target={"_blank"}>
                        <img src={GetStaticContentBaseUrl() + 'MagicGardenMobile1.png'} alt="Magic Garden"/>
                        <div className={styles.gameTitle}>Magic Garden</div>
                    </a>

                    <a className={styles.gameItem + ' ' + styles.bigGameItem} href={GetGameRedirectUrl('blazinghot40')} target={'_blank'}>
                        <img src={GetStaticContentBaseUrl() + 'BurningIce40Mobile1.png'} alt="Burning Ice"/>
                        <div className={styles.gameTitle}>Burning Ice</div>
                    </a>

                    <div className={styles.gameSmallIcons}>
                        <div className={styles.sectionOne}>
                            <a className={styles.gameItem + ' ' + styles.gameItemSmall} href={GetGameRedirectUrl('MoonStone')} target={'_blank'}>
                                <img src={GetStaticContentBaseUrl() + 'MoonstoneMobile1.png'} alt="Moonstone"/>
                               
                                <div className={styles.gameTitle}>MoonStone</div>

                            </a>
                            <a className={styles.gameItem + ' ' + styles.gameItemSmall} href={GetGameRedirectUrl('Cappadocia')} target={'_blank'}>
                                <img src={GetStaticContentBaseUrl() + 'CappadociaMobile1.png'} alt="Cappadocia"/>
                                <div className={styles.gameTitle}>Cappadocia</div>

                            </a>
                        </div>
                        <div className={styles.sectionTwo}>
                            <a className={styles.gameItem + ' ' + styles.gameItemSmall} href={GetGameRedirectUrl('SweetCandy')} target={'_blank'}>
                                <img src={GetStaticContentBaseUrl() + 'SweetCandyMobile1.png'} alt="SweetCandy"/>
                               
                                <div className={styles.gameTitle}>SweetCandy</div>

                            </a>
                            <a className={styles.gameItem + ' ' + styles.gameItemSmall} href={GetGameRedirectUrl('Balloon')} target={'_blank'}>
                                <img src={GetStaticContentBaseUrl() + 'BalloonMobile1.jpg'} alt="Balloon"/>
                                <div className={styles.gameTitle}>Balloon</div>

                            </a>
                        </div>
                    </div>
                </div>
            </div> */}
                <PartnersSlider />
            </>
        )
    );
};

export default HomePage;
