import React, { useCallback, useEffect, useState } from "react";
import styles from "./Header.module.css";
import HeaderLogo from "../../../../Components/HeaderLogo/HeaderLogo";
import { Link, NavLink } from "react-router-dom";
import Search from "../../../../Components/Search/Search";
import LeftMenu from "../../../../Components/LeftMenu/LeftMenu";
import ContactPopup from "../../../../Components/ContactPopup/ContactPopup";

const Header = () => {
  // const [showHeader, setShowHeader] = useState(true)
  // const [y, setY] = useState(window.scrollY);

  // const handleNavigation = useCallback(
  //     currentEvent => {
  //         const window = currentEvent.currentTarget;
  //         if (y > window.scrollY) {
  //             setShowHeader(true)
  //         } else if (y < window.scrollY) {
  //             setShowHeader(false)
  //         }
  //         setY(window.scrollY);
  //     }, [y]
  // );

  // useEffect(() => {
  //     setY(window.scrollY);
  //     window.addEventListener("scroll", handleNavigation);

  //     return () => {
  //         window.removeEventListener("scroll", handleNavigation);
  //     };
  // }, [handleNavigation]);
  const [isContactPopupVisible, setIsContactPopupVisible] = useState(false);
  return (
    <>
      {isContactPopupVisible && (
        <ContactPopup onClose={() => setIsContactPopupVisible(false)} />
      )}
      {
        // showHeader &&
        <header className={styles.header + " " + styles.showHeader}>
          <HeaderLogo />
          <div className={styles.headerMenuItems}>
            <NavLink
              activeClassName={styles.activeMenuItem}
              className={styles.headerMenuItem}
              to={"about-us"}
            >
              About Us
            </NavLink>
            <NavLink
              activeClassName={styles.activeMenuItem}
              className={styles.headerMenuItem}
              to={"licenses"}
            >
              Our Licenses
            </NavLink>
            <NavLink
              activeClassName={styles.activeMenuItem}
              className={styles.headerMenuItem}
              to={"partners"}
            >
              Our Partners
            </NavLink>
            {/* <NavLink activeClassName={styles.activeMenuItem} className={styles.headerMenuItem}
                                 to={"meet-us"}>Meet
                            Us</NavLink> */}
            <div className={styles.headerMenuItem + " " + styles.withSubMenu}>
              Games
              <div className={styles.subMenuItemsWrapper}>
                {/* <NavLink className={styles.subMenuItem + ' ' + styles.allItems}
                                         to={"/news?category=All"}>All</NavLink>
                                <NavLink className={styles.subMenuItem} to={"/news?category=Releases"}>
                                    Releases</NavLink>
                                <NavLink className={styles.subMenuItem} to={"/news?category=Promotions"}>
                                    Promotions</NavLink>
                                <NavLink className={styles.subMenuItem} to={"/news?category=PartnerShips"}>
                                    PartnerShips</NavLink>
                                <NavLink className={styles.subMenuItem} to={"/news?category=Awards"}>Awards</NavLink>
                                <NavLink className={styles.subMenuItem} to={"/news?category=Events"}>Events</NavLink>
                                <NavLink className={styles.subMenuItem} to={"/news?category=BlogPosts"}>BlogPosts</NavLink>
                                <NavLink className={styles.subMenuItem} to={"/career"}>Career</NavLink> */}
                <LeftMenu />
              </div>
            </div>

            {/* <div className={styles.headerMenuItem} onClick={() => setIsContactPopupVisible(true)}>Contact Us
                        </div> */}
          </div>
          <Search />
          <div className={styles.socialMediaWrapper}>
            <a
              href="https://www.instagram.com/smartsoftgaming"
              target="_blank"
              className={styles.socialMediaItem + " " + styles.instagram}
            />
            <a
              href="https://twitter.com/SmartSoftGaming"
              target="_blank"
              className={styles.socialMediaItem + " " + styles.twitter}
            />
            <a
              href="https://www.linkedin.com/company/smartsoft-gaming"
              target="_blank"
              className={styles.socialMediaItem + " " + styles.linkedin}
            />
            {/* <a href="https://www.youtube.com/channel/UCjXNMFsvmHVPgvae_A0O0Xg" target="_blank"
                           className={styles.socialMediaItem + ' ' + styles.youtube}/> */}
            <a
              href="https://www.facebook.com/SmartSoftGaming/"
              target="_blank"
              className={styles.socialMediaItem + " " + styles.facebook}
            />
          </div>
        </header>
      }
      {/* {<LeftMenu/>} */}
    </>
  );
};

export default Header;
