import React, {Component, useEffect, useState} from 'react';
import CustomSlider from "../../../Components/Slider/Slider";
import styles from './HomePage.module.css'
import {GetBanners, GetCategories} from "../../../Services/service";
import { GetGameRedirectUrl, GetStaticContentBaseUrl } from "../../../Services/common";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import PartnersSlider from "../../../Components/PartnersSlider/PartnersSlider";



const HomePage = () => {
    const [data, setData] = useState(null);
    const [banner, setBanner] = useState(null);
    useEffect(() => {
        GetCategories()
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });
        GetBanners('Main', "Desktop")
            .then(response => {
                setBanner(response.data)
            })
            .catch(err => {

            });

    }, [])

    return (
        banner && <>
            <Helmet>
            <style>{"body { background-color: var(--body-home-background); }"}</style>
            </Helmet>
            {
                    <>
                        {
                        banner[0].isExternalRedirect ?
                                    <a href={banner[0].redirectUrl} target={banner[0].isGameRedirect || banner[0].isExternalRedirect ? '_blank' : '_self'}>
                                        <img className={styles.mainBanner} src={GetStaticContentBaseUrl() + banner[0].filePath} alt={banner[0].imageAltAttribute}/>
                                    </a>
                                : 
                                    <Link to={banner[0].redirectUrl}>
                                        <img className={styles.mainBanner} src={GetStaticContentBaseUrl() + banner[0].filePath} alt={banner[0].imageAltAttribute}/>
                                    </Link>
                        }

                        </>
                    
            }
            <div className={styles.gamesContainer}>
                <div className={styles.gamesWrapper}>
                {
                       Array.isArray(data) && data.length > 0 &&
                        data.map((item, index) =>
                           
                        <div  key={index} className={styles.hoverDiv}>
                           <NavLink key={index} to={'' + item.redirectUrl} className={styles.gamesItem + ' ' + styles[item.categoryName]}>
                               <img className={styles.categoryImg} src={'/assets/images/HomePageDesktop/'+item.categoryName+'.png'}></img>
                               <div className={styles.categoryTitle + ' ' + styles[item.categoryName]}>{item.categoryName}</div>
                               <div className={styles[item.categoryName] +' ' +styles.gamesItemIcon}/>
                            </NavLink>
                        </div>
                       )
                   }
                </div>
            </div>
        <PartnersSlider />
        </>
    );
};

export default HomePage;
